@import "~bootstrap/scss/bootstrap";
@import '~jquery-ui/themes/base/all.css';

@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');

// Turista
* {
    font-family: 'Montserrat', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.homepage-carousel-container {
    height: 600px;
}

@media screen and (max-width: 768px) {
    .homepage-carousel-container {
        height: 220px;
    }
}

.homepage-carousel {
    height: 100%;
    width: 100%;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
}

.btn-turista-rounded {
    font-size: 15px;
    font-weight: 700;
    border-radius: 50px;
    padding: 10px 30px;
    border-width: 2px;
}

.fab-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  position: fixed;
  bottom: 30px; right: 30px;
  z-index: 1024;
  &:hover {
    height: 100%;
    .sub-button:nth-child(2) {
        transform: translateY(-80px);
    }
    .sub-button:nth-child(3) {
        transform: translateY(-140px);
    }
    .sub-button:nth-child(4) {
        transform: translateY(-200px);
    }
    .sub-button:nth-child(5) {
        transform: translateY(-260px);
    }
    .sub-button:nth-child(6) {
        transform: translateY(-320px);
    }
  }
  .fab {
    position: relative;
    height: 70px; width: 70px;
    background-color: rgb(10, 70, 130) !important;
    border-radius: 50%;
    z-index: 2;
    &::before {
      content: " ";
      position: absolute;
      bottom: 0; right: 0;
      height: 35px; width: 35px;
      background-color: inherit;
      border-radius: 0 0 10px 0;
      z-index: -1;
    }
    .fab-content {
      display: flex;
      align-items: center; justify-content: center;
      height: 100%; width: 100%;
      border-radius: 50%;
      .material-icons {
        color: white;
        font-size: 48px;
      }
    }
  }
  .sub-button {
    position: absolute;
    display: flex;
    align-items: center; justify-content: center;
    bottom: 10px; right: 10px;
    height: 50px; width: 50px;
    background-color: rgb(10, 70, 130) !important;
    border-radius: 50%;
    transition: all .3s ease;
    &:hover {
      cursor: pointer;
    }
    .material-icons {
      color: white;
      padding-top: 6px;
    }
  }
}